/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import Layout from '../components/Layout'
import Wrapper from '../components/Wrapper'
import FlexContainer from '../components/FlexContainer'
import Text from '../components/Text'

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <FlexContainer
        css={theme => css`
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding: 20px 0 80px;

          ${theme.queries.medium} {
            padding: 40px 0;
          }
        `}
      >
        <Text color="arsenic" size="44" lineHeight="80" mobileAlign="center">
          404
        </Text>
        <Text color="gray" size="26" mobileAlign="center" mobileHeight="30">
          Página não encontrada
        </Text>
      </FlexContainer>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
